import React, {useEffect} from 'react';

function App() {
  useEffect(() => {
    console.log(window.location);
    if(window.location.host.includes('systems')) {
      window.location.href = 'https://systems.rerengatahi.nz';
    } else if (window.location.host.includes('knowledgebase')) {
      window.location.href = 'https://knowledgebase.rerengatahi.nz';
    } else {
      window.location.href = 'https://rerengatahi.nz';
    }
  }, []);
  return (
    <div className="App" style={{display: 'flex', flexDirection: 'column', height: '100vh', width: '100vw', textAlign: 'center'}}>
      <div style={{flex: 2}}/>
        <div>
          Redirecting to rerengatahi.nz
        </div>
      <div style={{flex: 3}}/>
    </div>
  );
}

export default App;
